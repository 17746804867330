body {
  /* stops scroll freez for element scroll */
  overflow: hidden;
  margin: 0;
  height: 100%;
}
#root {
  overflow: hidden;
  height: 100%;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
.diamond-grid {
  width: inherit;
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  overflow-y: auto;
  background: #efeff4;
}
.diamond-col {
  width: 32%;
  background: white;
  padding: 0px;
  margin: 4px;
}
.diamond-col {
  width: 32%;
  background: white;
  padding: 0px;
  margin: 4px;
}
.diamond-img {
  width: 100%;
}
.diamond-mainLine {
  font-family: Roboto;
  font-size: 16px;
}
.diamond-secondLine {
  font-family: Roboto;
  font-size: 13px;
}
.mined-grid {
  display: flex;
  width: 100px;
  border: 1px solid;
}
.shape-grid {
  display: flex;
  width: 400px;
  border: 1px solid;
}
.shape-col {
  flex: 1;
  justify-content: center;
}
.filter-fab {
  position: fixed;
  top: 70px;
  right: 20px;
  z-index: 100;
  opacity: 0.8;
}
.draw-container {
  display: flex;
  justify-content: flex-end;
}
.draw-col {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.draw-btn {
  padding: 5px;
  font-size: 12px;
  border-color: grey;
  margin-bottom: 3px;
  width: 60px;
}
.pencil-label {
  position: absolute;
  right: 7px;
  top: 7px;
  color: grey;
}
.draw-sm-btn {
  padding: 5px;
  font-size: 12px;
  border-color: grey;
  margin-bottom: 3px;
  width: 30px;
  float: left;
}
